.root {
  filter: blur(10px);
  transition: filter var(--transition-fast);
  /* Fix safari shadow bug */
  transform: translate3d(0, 0, 0);
}

.root[data-loaded] {
  filter: blur(0);
}
